import "./styles.scss";

const checkbox = document.getElementById('checkbox-rgpd');
const submit   = document.getElementById('button-submit');
const form   = document.getElementById('form-data');

checkbox.addEventListener('change', (event) => {
  if (event.target.checked) {
    submit.removeAttribute('disabled');
  } else {
    submit.setAttribute('disabled', "");
  }
});

form.addEventListener('submit', (event) => {
  event.preventDefault();

  sendDataForm(event.target);
  event.target.reset();
});


function sendDataForm(form) {
  var XHR = new XMLHttpRequest();
  var FD = transformFormData(form);

  XHR.addEventListener("load", function(event) {
    alert('Muito Obrigado pela inscrição');
  });

  XHR.addEventListener("error", function(event) {
    alert('Uppss! Error. Por favor, tente mais tarde.');
  });

  XHR.open("POST", form.action);
  XHR.send(FD);
}

function transformFormData(form){
  var tempFormData = new FormData();
  var formData = new FormData(form);

  for(var pair of formData.entries()) {
    const field = form.querySelector('[name=' + pair[0] + ']');
    const label = field && field.dataset.label ? field.dataset.label: pair[0];

    tempFormData.append(pair[0] + '[val]', pair[1]);
    tempFormData.append(pair[0] + '[label]', label);
  }

  return tempFormData;

}
